import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Col, Row } from "antd";
import { useEffect } from "react";

export default ({ edit, setData }) => {
  const editorConfig = {
    toolbar: [
      "heading",
      "|",
      "bold",
      "italic",
      "link",
      "bulletedList",
      "numberedList",
      "blockQuote",
    ],
    heading: {
      options: [
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
      ],
    },
  };

  useEffect(() => {
    if (edit) {
      setData({
        content_right: edit.content_right,
        contents: edit.contents,
      });
    }
  }, []);

  return (
    <Row gutter={16}>
      <Col span={12}>
        <CKEditor
          config={editorConfig}
          editor={ClassicEditor}
          data={edit ? edit.contents : false}
          onChange={(event, editor) => {
            setData((state) => ({ ...state, contents: editor.getData() }));
          }}
        />
      </Col>
      <Col span={12}>
        <CKEditor
          config={editorConfig}
          editor={ClassicEditor}
          data={edit ? edit.content_right : false}
          onChange={(event, editor) => {
            setData((state) => ({ ...state, content_right: editor.getData() }));
          }}
        />
      </Col>
    </Row>
  );
};
