import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { postData } from "../../services/api-service";
import { useHistory } from "react-router-dom";
import { apiList } from "../../services/api-list";

const AdmissionForm = ({ edit, close }: { edit?: any; close?: any }) => {
  const router = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const programTypes = ["Full-Time", "Part-Time", "Both"];
  const status = [
    { id: 1, name: "Active" },
    { id: 2, name: "Inactive" },
  ];

  useEffect(() => {
    edit &&
      form.setFieldsValue({
        ...edit,
        start_date: moment(edit?.start_date),
        end_date: moment(edit?.end_date),
        result_published_at: moment(edit?.result_published_at),
        exam_time: moment(edit?.exam_time),
        viva_time: moment(edit?.viva_time),
      });

    // return alert('pop')
  }, [edit]);

  const submit = async (values: any) => {
    const dateFormat = "YYYY-MM-DD";
    const dateTimeFormat = "YYYY-MM-DD H:mm:ss";
    setLoading(true);
    values.start_date = moment(values.start_date).format(dateFormat);
    values.end_date = moment(values.end_date).format(dateFormat);
    values.result_published_at = moment(values.result_published_at).format(
      dateTimeFormat
    );
    values.exam_time = moment(values.exam_time).format(dateTimeFormat);
    values.viva_time = moment(values.viva_time).format(dateTimeFormat);

    const res = await postData({
      query: edit
        ? `${apiList?.editAdmission}/${edit?.id}`
        : apiList?.createAdmission,
      data: values,
    });
    if (res?.data?.code == 200) {
      notification.success({
        message: "Success",
        description: edit ? "Admission updated!" : "Admission created!",
      });
      if (edit) close();
      else router?.push("/dashboard/admissions");
    } else {
      res?.data?.errors &&
        Object.values(res?.data?.errors).map((item: any) => {
          notification?.error({
            message: item[0],
          });
        });
    }
    setLoading(false);
  };

  return (
    <Form layout="vertical" form={form} onFinish={submit}>
      <Row className="space" gutter={12}>
        <Col md={12} xs={24}>
          <FormItem name="title" label="Exam Title">
            <Input size="large" placeholder="Exam Title" />
          </FormItem>
          <FormItem name="start_date" label="Application Start Date">
            <DatePicker size="large" />
          </FormItem>
          <Row gutter={12}>
            <Col span={16}>
              <FormItem name="exam_time" label="Exam Start Date Time">
                <DatePicker size="large" showTime />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem name="exam_hour" label="Exam Hour">
                <InputNumber size="large" placeholder="Hour" min={1} max={5} />
              </FormItem>
            </Col>
          </Row>
          <FormItem name="status" label="Status">
            <Select size="large" placeholder="Select Status">
              {status.map((value: any, key: number) => (
                <Select.Option key={key} value={value.id}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem>
            <Button
              loading={loading}
              block
              htmlType="submit"
              type="primary"
              size="large"
            >
              Submit
            </Button>
          </FormItem>
        </Col>
        <Col md={12} xs={24}>
          <FormItem name="program_type" label="Program Type">
            <Select size="large" placeholder="Select Program Type">
              {programTypes.map((type: any) => (
                <Select.Option value={type} key={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </FormItem>
          <FormItem name="end_date" label="Application End Date">
            <DatePicker size="large" />
          </FormItem>
          <FormItem name="viva_time" label="Viva Date Time">
            <DatePicker showTime size="large" />
          </FormItem>
          <FormItem name="result_published_at" label="Result Published On">
            <DatePicker showTime size="large" />
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default AdmissionForm;
