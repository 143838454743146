import { Button, Checkbox, Col, DatePicker, Form, Input, Row, notification } from "antd";
import FormItem from "antd/lib/form/FormItem";
import { calculateTimeDuration, checkTimeDuration } from "../../services/helper";
import React, { useEffect, useState } from "react";
import moment from "moment";

export const WorkExp = ({
  id,
  labels,
  isSubmitting,
  setExp,
  edit,
  destroy,
  last,
  required,
}: {
  id?: number;
  labels?: boolean;
  isSubmitting?: boolean;
  setExp: any;
  edit?: any;
  destroy?: any;
  last?: boolean;
  required?: boolean;
}) => {
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rules, setRules] = useState(
    required ? [{ required: true, message: "Required" }] : []
  );

  useEffect(() => {
    isSubmitting && setExp({ form: form, id: id });
  }, [isSubmitting]);

  useEffect(() => {
    setExp({ form: form, id: id });
    edit &&
      form.setFieldsValue({
        ...edit,
        date_form: moment(edit?.date_form),
        date_to: edit?.date_to ? moment(edit?.date_to) : moment(),
      });
    edit && edit?.date_to == null && setChecked(true);
    setStartDate(edit?.date_form);
    setEndDate(edit?.date_to);
  }, []);

  useEffect(() => {
    if (required) setRules([{ required: true, message: "Required" }]);
    else setRules([]);
    // console.log(rules);
  }, [required]);

  useEffect(() => {
    setDuration(calculateTimeDuration(endDate, startDate));
  }, [startDate, endDate]);

  return (
    <Form
      autoComplete="off"
      // scrollToFirstError={true}
      // onValuesChange={() => setExp(form.getFieldsValue())}
      form={form}
      layout="vertical"
    >
      <Row gutter={24}>
        <Col md={4} xs={24}>
          <FormItem
            rules={rules}
            name="organization"
            label={labels && "Organization"}
          >
            <Input size="large" placeholder="Organization" />
          </FormItem>
        </Col>
        <Col md={4} xs={24}>
          <FormItem
            rules={rules}
            name="designation"
            label={labels && "Position"}
          >
            <Input size="large" placeholder="Position" />
          </FormItem>
        </Col>
        <Col md={4} xs={24}>
          <FormItem
            rules={rules}
            name="responsibility"
            label={labels && "Responsibility"}
          >
            <Input size="large" placeholder="Responsibility" />
          </FormItem>
        </Col>
        <Col md={3} xs={24}>
          <FormItem
            rules={rules}
            name="date_form"
            label={labels && "Start Date"}
          >
            <DatePicker
              placeholder="Start"
              disabledDate={(current: any) =>
                current && current > moment().endOf("day")
              }
              onChange={(date: any) => {
                if(0 <= checkTimeDuration(endDate, date)){
                  setStartDate(date);
                }else{
                  setStartDate(null);
                  form.setFieldsValue({
                    date_form: null,
                  });
                  notification.error({ message: `Start Date can't set after End Date` });
                }
              }}
              size="large"
            />
          </FormItem>
        </Col>
        <Col md={3} xs={24}>
          <FormItem rules={rules} name="date_to" label={labels && "End Date"}>
            <DatePicker
              placeholder="End"
              disabledDate={(current: any) =>
                current && current > moment().endOf("day")
              }
              onChange={(date: any) => {
                if(0 <= checkTimeDuration(date, startDate)){
                  setEndDate(date);
                }else{
                  setEndDate(null);
                  form.setFieldsValue({
                    date_to: null,
                  });
                  notification.error({ message: `End Date can't set before Start Date` });
                }
              }}
              size="large"
              disabled={checked}
            />
          </FormItem>
        </Col>
        <Col md={3} xs={24}>
          <FormItem name="duration" label={labels && "Duration"}>
            <text>{duration}</text>
          </FormItem>
        </Col>
        <Col md={3} xs={24}>
          {labels && (
            <FormItem className="current-check" name="currently">
              <Checkbox
                checked={checked}
                onChange={(e: any) => {
                  setChecked(e?.target?.checked);
                  if (e?.target?.checked){
                    let newDate: any = moment();
                    setEndDate(newDate);
                    form.setFieldsValue({
                      date_to: moment(),
                    });
                  }
                }}
              />
              <span> Current</span>
            </FormItem>
          )}
          {!labels && <Button onClick={() => destroy(id)}>Remove</Button>}
        </Col>
      </Row>
    </Form>
  );
};
