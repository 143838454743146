import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EduInfoForm } from "../../components/FormGenerator/Education";
import { PersonalInfoForm } from "../../components/FormGenerator/Personal";
import { WorkExp } from "../../components/FormGenerator/WorkExp";
import { Header } from "../../components/Header";
import { apiList } from "../../services/api-list";
import { getData, postData } from "../../services/api-service";
import { primaryFields } from "./personalFormFields";
import moment from "moment";
import "./registration.scss";
import { Gpainfo } from "../../components/GPAinfo";

const RegistrationForm = ({ close, edit }: { close?: any; edit?: any }) => {
  const router = useHistory();
  const [commonDropDown, setCommonDropDown] = useState<any>();
  const [personal, setPersonal] = useState<any>();
  const [files, setFiles] = useState<any>({});
  const [school, setSchool] = useState<any>();
  const [college, setCollege] = useState<any>();
  const [uni, setUni] = useState<any>();
  const [exp, setExp] = useState<any>([]);
  const [othr, setOthr] = useState<string>();
  const [adType, setAdType] = useState<any>();
  const [expCount, setExpCount] = useState<any>(
    edit?.jobs
      ? edit.jobs.map((item: any, i: number) => ({
          id: item.id,
          edit: item,
          labels: i === 0,
        }))
      : [{ id: 0, labels: true }]
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [ptype, setPtype] = useState<any>("pp");

  const getCircular = async () => {
    const res = await getData({
      query: apiList.currentAdmissions,
    });
    if (
      res?.data?.data?.end_date &&
      moment(res?.data?.data?.end_date).diff(moment(), "days") >= 0 &&
      moment(res?.data?.data?.start_date).diff(moment(), "hours") < 0
    ) {
      setAdType(res.data.data.program_type);
    }// else router?.push("/auth/login"); // TODO user update
  };

  useEffect(() => {
    getCircular();
  }, []);

  const submit = async () => {
    setIsSubmitting(true);
    let pers: any = null;
    try {
      pers = await personal.validateFields();
    } catch (e) {
      window.scrollTo(0, 200);
    }

    try {
      const sch = await school.validateFields();
      const col = await college.validateFields();
      const un = await uni.validateFields();

      let ex: any[] = [];
      for (let i = 0; i < exp?.length; i++) {
        const temp = await exp[i].form.validateFields();
        if (
          temp &&
          temp.organization &&
          temp.designation &&
          temp.responsibility
        ) {
          let data = {
            ...edit?.jobs[i],
            ...temp,
            currently_working: temp?.current ? 1 : 0,
            date_form: moment(temp.date_form).format("YYYY-MM-DD"),
            date_to: moment(temp.date_to).format("YYYY-MM-DD"),
          };
          if (edit) data.id = edit.jobs[i]?.id;
          ex.push(data);
        }
      }
      //POST to api
      let payload = {
        admission_id: "1",
        ...pers,
        date_of_birth: moment(pers?.date_of_birth).format("YYYY-MM-DD"),
        ...files,
        educations: `[${JSON.stringify({
          ...edit?.education[0],
          ...sch,
        })},${JSON.stringify({
          ...edit?.education[1],
          ...col,
        })},${JSON.stringify({ ...edit?.education[2], ...un })}]`,
        jobs: JSON.stringify(ex),
      };

      if (othr) payload.qualifications = othr;
      delete payload.pic;
      delete payload.sig;

      const res =
        pers &&
        (await postData({
          query: edit
            ? `${apiList?.updateApplicant}/${edit?.id}`
            : apiList?.createApplicant,
          data: payload,
          noToken: edit ? false : true,
        }));
      if (res?.data?.code === 200) {
        if (edit) {
          notification.success({ message: "Updated!" });
          close();
        } else {
          notification.success({ message: "Registration successful" });
          router.push("/thankyou");
        }
      } else if (res?.data?.errors) {
        Object?.values(res?.data?.errors)?.map((item: any) => {
          notification?.error({ message: item });
        });
      } else notification?.error({ message: "Fill up all fields correctly!" });
    } catch (e) {
      notification.error({
        message: "Fill up all fields correctly!",
      });
      console.log("Submit error >>>", e);
    }
    setIsSubmitting(false);
  };

  const getCommonDropDown = async () => {
    const res = await getData({
      query: apiList?.commonDropDown,
      noToken: true,
    });
    if (res?.data) setCommonDropDown(res?.data?.data);
    else router?.push("/");
  };

  const removeJobField = (id: number) => {
    setExpCount((expc: any) => expc.filter((item: any) => item.id !== id));
    setExp((exp: any) => exp.filter((item: any) => item.id !== id));
  };

  useEffect(() => {
    getCommonDropDown();
  }, []);

  return (
    <div className="reg-wrapper">
      {!edit && <Header />}
      <div className="sub-container space">
        {commonDropDown && (
          <div>
            <Divider>
              <h1>Personal information</h1>
            </Divider>
            <div className="space" />
            <PersonalInfoForm
              setPtype={setPtype}
              adType={adType}
              fields={primaryFields}
              setPer={setPersonal}
              countries={commonDropDown?.countries}
              isSubmitting={isSubmitting}
              edit={edit}
              fileList={setFiles}
            />
            <Divider>
              <div>
                <h1>Educational information</h1>
              </div>
            </Divider>
            <div className="space" />
            <Gpainfo />
            <EduInfoForm
              labels={true}
              degrees={commonDropDown?.degrees?.SSC}
              setEdu={setSchool}
              edit={edit?.education[0]}
              isSubmitting={isSubmitting}
            />
            <EduInfoForm
              degrees={commonDropDown?.degrees?.HSC}
              setEdu={setCollege}
              edit={edit?.education[1]}
              isSubmitting={isSubmitting}
            />
            <EduInfoForm
              degrees={commonDropDown?.degrees?.Bachelor}
               universities={commonDropDown?.universities}
              setEdu={setUni}
              edit={edit?.education[2]}
              isSubmitting={isSubmitting}
              uni={true}
            />
            <Form layout="vertical">
              <Form.Item label="Other qualifications">
                <Input.TextArea
                  defaultValue={edit && edit.qualifications}
                  onChange={(e: any) => setOthr(e?.target?.value)}
                  placeholder="Other qualifications"
                />
              </Form.Item>
            </Form>
            <Divider>
              <h1>Work experience</h1>
            </Divider>
            <div className="space" />
            {expCount.map((item: any) => (
              <WorkExp
                required={ptype === "Full-Time" ? false : true}
                key={item.id}
                id={item.id}
                labels={item.labels}
                edit={item.edit}
                destroy={removeJobField}
                setExp={(value: any) => setExp((exp: any) => [...exp, value])}
              />
            ))}
            <Button
              onClick={() =>
                setExpCount((expc: any) => [...expc, { id: new Date() }])
              }
            >
              + Add experience
            </Button>
            <div className="double-space" />
            <Row gutter={12}>
              <Col
                lg={{ span: 6, offset: 6 }}
                md={{ span: 6, offset: 6 }}
                xs={24}
              >
                <Button
                  loading={isSubmitting}
                  style={{ marginBottom: "1rem" }}
                  onClick={submit}
                  size="large"
                  type="primary"
                  block
                >
                  {edit ? "Update" : "Submit"}
                </Button>
              </Col>
              {!edit && (
                <Col lg={6} md={6} xs={24}>
                  <Button
                    onClick={() => router?.push("/")}
                    size="large"
                    type="ghost"
                    block
                  >
                    Go to homepage
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        )}
        {!commonDropDown && <Skeleton className="space" active />}
      </div>
    </div>
  );
};

export default RegistrationForm;
