import { Alert } from "antd";
import React from "react";

export const Gpainfo = () => (
  <>
    <Alert
      type="info"
      message={
        <div className="gpa-info">
          <p
            style={{
              textAlign: "center",
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >{`A level & O level GPA Calculation:`}</p>
          <ul>
            <li>
              Letter grades of O level and A level subjects will be converted
              into grade points as follows: A=4, B=3, C=2 and D=1.
            </li>
            <li>
              GPA will be calculated by taking average of the best five grades
              (Only one D grade is acceptable) for O level and the best two
              grades (Only one D grade is acceptable) for A level. While
              calculating the GPA, grades below D will not be considered.
            </li>
            <li>
              Minimum GPA of 2.00 in both O level exam and A level exam
              separately.
            </li>
          </ul>
        </div>
      }
    />
    <div className="space" />
  </>
);
