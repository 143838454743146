import { Button, Divider, Pagination, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { apiList } from "../../../services/api-list";
import { getData } from "../../../services/api-service";

const Downloads = () => {
  const [list, setList] = useState<any>();
  const [total, setTotal] = useState<number>();
  const [tabLoading, setTabLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getList = async () => {
    setTabLoading(true);
    const res = await getData({
      query: apiList?.downloadList,
      data: {
        pagination: 10,
        page: page,
      },
    });
    if (res?.data?.code == 200) {
      setList(res?.data?.data?.downloads?.data);
      setTotal(res?.data?.data?.downloads?.total);
    }
    setTabLoading(false);
  };

  useEffect(() => {
    getList();
  }, [page]);

  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Module", dataIndex: "module", key: "module" },
    { title: "Date/Time", dataIndex: "created_at", key: "created_at" },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        if (record.status) {
          return (
            <Tag
              key={record.id}
              color={
                record.status === 1
                  ? "#74a758"
                  : record.status === 2
                  ? "#be4652"
                  : "#086dd9"
              }
            >
              {record.status === 1
                ? "Ready"
                : record.status === 2
                ? "Failed"
                : "Processing"}
            </Tag>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      render: (text: string, record: any) => {
        return (
          <Button
            onClick={() => {
              window.open(record?.url, "_blank");
            }}
            disabled={record?.status != 1}
            type="primary"
          >
            Download
          </Button>
        );
      },
    },
  ];

  return (
    <div className="dashboard-child">
      <div className="db-child-header">
        <h2>Downloads</h2>
      </div>
      <Divider />
      <Table
        loading={tabLoading}
        pagination={false}
        columns={columns}
        dataSource={list}
      />
      <div className="space" style={{ float: "right" }}>
        <Pagination
          current={page}
          showSizeChanger={false}
          onChange={(value) => setPage(value)}
          total={total}
        />
      </div>
    </div>
  );
};

export default Downloads;
