import { Login } from "../components/Login";
import Auth from "../pages/Auth";
import Dashboard from "../pages/Dashboard";
import Admissions from "../pages/Dashboard/Admissions";
import CreateAdmission from "../pages/Dashboard/Admissions/Create";
import Applicants from "../pages/Dashboard/Applicants";
import ApplicantDetails from "../pages/Dashboard/Applicants/Details";
import Downloads from "../pages/Dashboard/Downloads";
import EditCircular from "../pages/Dashboard/EditCircular";
import CreatePage from "../pages/Dashboard/EditCircular/Create";
import ExamCentres from "../pages/Dashboard/ExamCentres";
import CreateCentre from "../pages/Dashboard/ExamCentres/Create";
import SendNotification from "../pages/Dashboard/Notifications";
import StudentProfile from "../pages/Dashboard/StudentProfile";
import RegistrationForm from "../pages/Registration";
import Welcome from "../pages/Welcome";
import ThankYou from "../pages/ThankYou";
import { auth } from "../services/auth";

export const routes = [
  // { id: 1, path: "/", component: auth?.isLoggedIn() ? Welcome : Welcome },
  !auth?.isLoggedIn() && { id: 2, path: "/apply", component: RegistrationForm },
  !auth?.isLoggedIn() && { id: 3, path: "/auth", component: Auth },
  { id: 4, path: "/dashboard", component: Dashboard },
  { id: 5, path: "/dashboard/:menu", component: Dashboard },
  { id: 7, path: "/dashboard/:menu/:details", component: Dashboard },
  { id: 8, path: "/thankyou", component: ThankYou },
  !auth?.isLoggedIn() && { id: 6, path: "/auth/:child", component: Auth },
];

export const authRoutes = [
  { id: 1, path: "/auth/login", component: Login },
  { id: 2, path: "/auth/forget-password", component: Welcome },
];

export const adminDashboardRoutes = [
  { id: 1, path: "/dashboard/admissions", component: Admissions },
  { id: 2, path: "/dashboard/applicants", component: Applicants },
  { id: 3, path: "/dashboard/centres", component: ExamCentres },
  {
    id: 4,
    path: "/dashboard/admissions/create",
    component: CreateAdmission,
  },
  {
    id: 5,
    path: "/dashboard/applicants/:details",
    component: ApplicantDetails,
  },
  { id: 6, path: "/dashboard/centres/:create", component: CreateCentre },
  { id: 7, path: "/dashboard/downloads", component: Downloads },
  { id: 8, path: "/dashboard/notifications", component: SendNotification },
  { id: 9, path: "/dashboard/edit-circular", component: EditCircular },
  { id: 10, path: "/dashboard/edit-circular/create", component: CreatePage },
  // { id: 3, path: "/dashboard/applicants/details", component: Welcome },
];

export const studentDashboardRoutes = [
  { id: 1, path: "/dashboard/student", component: StudentProfile },
];
