import React, { useEffect, useState } from "react";
import "./circular.scss";
import { Button, Card, Col, notification, Row, Spin } from "antd";
import { getData } from "../../services/api-service";
import { apiList } from "../../services/api-list";
import moment from "moment";
import { useHistory } from "react-router-dom";

export const Circular = () => {
  const router = useHistory();
  const [circular, setCircular] = useState<any>();
  const [homepage, setHomepage] = useState<any>();

  const getCircular = async () => {
    const res = await getData({
      query: apiList.currentAdmissions,
    });
    if (
      res?.data?.data?.end_date &&
      moment(res?.data?.data?.end_date).diff(moment(), "days") >= 0 &&
      moment(res?.data?.data?.start_date).diff(moment(), "hours") < 0
    ) {
      setCircular((cir: any) => ({
        ...cir,
        title: res?.data?.data?.title,
        examTime: res?.data?.data?.exam_time,
        lastDateOfApplication: res?.data?.data?.end_date,
        startDateOfApplication: res?.data?.data?.start_date,
      }));
      // fetch(column1)
      //   .then((response) => response.text())
      //   .then((text) => {
      //     setCircular((cir: any) => ({ ...cir, first: text }));
      //   });
      // fetch(column2)
      //   .then((response) => response.text())
      //   .then((text) => {
      //     setCircular((cir: any) => ({ ...cir, second: text }));
      //   });
    } else {
      router?.push("/auth/login");
    }
  };

  const getHomePage = async () => {
    const res = await getData({ query: `${apiList.pageDetails}home-page` });
    if (res?.data?.data) setHomepage(res.data.data);
    else notification.error({ message: `Failed to load circular` });
  };

  useEffect(() => {
    getCircular();
    getHomePage();
  }, []);

  return (
    <div className="circular-wrapper double-space">
      {circular ? (
        circular === "no-circular" ? (
          <Card className="title-card">
            <h1>No circular</h1>
          </Card>
        ) : (
          <div>
            <Card className="title-card">
              <h1>{circular?.title}</h1>
              <h5>
                Last Date of Applications : {circular?.lastDateOfApplication}
              </h5>
              <p>Helpline : +8801726417629</p>
            </Card>
            <div className="space" />
            <Card>
              <Row gutter={12}>
                <Col md={12} xs={24}>
                  {/* <ReactMarkdown source={circular?.first} /> */}
                  {homepage?.contents && (
                    <div
                      dangerouslySetInnerHTML={{ __html: homepage.contents }}
                    />
                  )}
                </Col>
                <Col md={12} xs={24}>
                  {/* <ReactMarkdown source={circular?.second} /> */}
                  {homepage?.content_right && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homepage.content_right,
                      }}
                    />
                  )}
                </Col>
              </Row>
              <div className="apply-wrapper">
                <Button
                  onClick={() => router?.push("/apply")}
                  type="primary"
                  size="large"
                  className="apply-btn"
                  block
                >
                  Apply Now
                </Button>
              </div>
            </Card>
          </div>
        )
      ) : (
        <div style={{ width: "100%", textAlign: "center" }}>
          <Spin />
        </div>
      )}
    </div>
  );
};
