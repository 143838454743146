export const apiList = {
  login: "login",
  currentAdmissions: "current-admission",
  createAdmission: "admission-create",
  editAdmission: "admission-update",
  commonDropDown: "common-dropdown",
  admissionList: "admission-list",
  applicantList: "user-list/student",
  applicantDownload: "student-pdf",
  applicantDetails: "user-details",
  createApplicant: "user-create",
  updateApplicant: "student-update",
  centreList: "center-list",
  createCentre: "center-create",
  updateCentre: "center-update",
  shortlistApplicant: "bulk-selected-for-exam",
  downloadAdmitCard: "student/admit-card",
  bulkDownload: "student-pdf-bulk",
  shortlistedStudentCSV: 'shortlisted-student-csv-generate',
  attendancePDF: 'attendance-pdf-center-wise',
  downloadList: "generated-pdf-download-list",
  sendNotification: "send-notification",
  verifyForeign: "verify-foreign/",
  pageList: "page-list",
  createPage: "page-create",
  editPage: "page-update/",
  pageDetails: "page-details/",
};
