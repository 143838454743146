import { Button, Divider, Drawer, Pagination, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AdmissionForm from "../../../components/FormGenerator/AdmissionForm";
import { apiList } from "../../../services/api-list";
import { getData } from "../../../services/api-service";

const Admissions = () => {
  const router = useHistory();
  const [tabLoading, setTabLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [list, setList] = useState<any>();
  const [drawer, setDrawer] = useState<any>();

  const getList = async () => {
    setTabLoading(true);
    let data = {
      sorting_type: "desc",
      pagination: 10,
      page: page,
      sorting: "id",
    };
    const res = await getData({
      query: apiList?.admissionList,
      data: data,
    });
    if (res?.data?.code === 200) {
      setList(res?.data?.data?.admissions);
      setTotal(res?.data?.data?.admissions?.total);
    }
    setTabLoading(false);
  };

  useEffect(() => {
    getList();
  }, [page]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Program Type",
      dataIndex: "program_type",
      key: "program_type",
    },
    {
      title: "Session Start",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "Last Date Of Application",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Writen Exam Date",
      key: "exam_time",
      dataIndex: "exam_time",
    },
    {
      title: "Viva Date",
      key: "viva_time",
      dataIndex: "viva_time",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (text: string, record: any) => {
        return (
          <Tag key={record?.id} color={record?.status === 1 ? "green" : "red"}>
            {record?.status === 1 ? "Active" : "Inactive"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type="primary" onClick={() => setDrawer(record)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="dashboard-child">
      <div className="db-child-header">
        <h2>Admission list</h2>
        <Button
          onClick={() => router?.push(`${router?.location?.pathname}/create`)}
          type="primary"
        >
          + Create Admission
        </Button>
      </div>
      <Divider />
      <Table
        loading={tabLoading}
        pagination={false}
        columns={columns}
        dataSource={list?.data}
      />
      <div className="space" style={{ float: "right" }}>
        <Pagination
          showSizeChanger={false}
          onChange={(value) => setPage(value)}
          total={total}
        />
      </div>
      <Drawer
        title="Edit Admission"
        width={700}
        placement="right"
        closable={true}
        onClose={() => setDrawer(null)}
        visible={drawer}
        key="right"
      >
        <AdmissionForm
          close={() => {
            getList();
            setDrawer(null);
          }}
          edit={drawer}
        />
      </Drawer>
    </div>
  );
};

export default Admissions;
