import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";

export const EduInfoForm = ({
  labels,
  isSubmitting,
  setEdu,
  degrees,
  universities,
  edit,
  uni,
}: any) => {
  const [form] = Form.useForm();
  const [type, setType] = useState<string>();
  const [examType, setExamType] = useState<any>();
  const rules = { rules: [{ required: true, message: "Required" }] };

  // const checkResult = (_: any, value: any) => {
  //   const result = Number(value);
  //   if (uni) {
  //     if (result >= 2.5) {
  //       return Promise.resolve();
  //     }
  //     return Promise.reject(new Error("Minimum is 2.5"));
  //   } else if (examType && (examType === "O Level" || examType === "A Level")) {
  //     if (result >= 2) return Promise.resolve();
  //     return Promise.reject(new Error("Minimum is 2"));
  //   } else if (examType) {
  //     if (result >= 3) return Promise.resolve();
  //     return Promise.reject(new Error("Minimum is 3"));
  //   } else if (!value) {
  //     return Promise.reject(new Error("Required"));
  //   }
  // };

  useEffect(() => {
    // isSubmitting && setEdu(form);
  }, [isSubmitting]);

  useEffect(() => {
    setEdu(form);
    edit && form.setFieldsValue({ ...edit });
  }, []);

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={12}>
        <Col md={4} xs={24}>
          <FormItem {...rules} label={labels && "Exam type"} name="degree_id">
            <Select
              onChange={(v: any) => {
                setExamType(degrees.filter((dg: any) => dg.id === v)[0].name);
                form.setFieldsValue({ result: null });
              }}
              size="large"
              placeholder="Exam type"
            >
              {degrees &&
                degrees.map((degree: { id: number; name: string }) => {
                  return (
                    <Select.Option value={degree.id}>
                      {degree.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </FormItem>
        </Col>
        <Col md={4} xs={24}>
          <FormItem
            {...rules}
            name="passing_year"
            label={labels && "Year of passing"}
          >
            <Input size="large" placeholder="Year" type="number" />
          </FormItem>
        </Col>
        <Col md={3} xs={24}>
          <FormItem {...rules} label={labels && "System"} name="result_type">
            <Select
              onChange={(value: string) => {
                setType(value);
                form.setFieldsValue({ result: null });
              }}
              size="large"
              placeholder="Type"
            >
              <Select.Option value={uni ? "CGPA" : "GPA"}>
                {uni ? "CGPA" : "GPA"}
              </Select.Option>
              <Select.Option value="Division">Division</Select.Option>
            </Select>
          </FormItem>
        </Col>
        <Col md={3} xs={24}>
          {type === "Division" || edit?.result_type === "Division" ? (
            <FormItem
              label={labels && "Result"}
              name="result"
              rules={[{ required: true, message: "Required" }]}
            >
              <Select size="large" placeholder="Result">
                <Select.Option value="1st">1st Division</Select.Option>
                <Select.Option value="2nd">2nd Division</Select.Option>
              </Select>
            </FormItem>
          ) : (
            <FormItem
              label={labels && "Result"}
              name="result"
              required
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    const result = parseFloat(getFieldValue(['result']));
                    if (uni) {
                      if (result >= 2.5) {
                        return Promise.resolve();
                      }else{
                        return Promise.reject(new Error("Minimum is 2.5"));
                      }
                    } else if (examType && (examType === "O Level" || examType === "A Level")) {
                      if (result >= 2){
                        return Promise.resolve();
                      }else{
                        return Promise.reject("Minimum is 2");
                      }
                    } else if (examType) {
                      if (result >= 3){ 
                        return Promise.resolve();
                      }else{
                        return Promise.reject("Minimum is 3");
                      }
                    } else if (!value) {
                      return Promise.reject("Required");
                    }else{
                      return Promise.resolve();
                    }
                  }
                })
              ]}
            >
              <Input size="large" type="number" placeholder="Result" />
            </FormItem>
          )}
        </Col>
        <Col md={4} xs={24}>
          <FormItem {...rules} label={labels && "Area/Subject"} name="subject">
            <Input size="large" placeholder="Area/Subject" />
          </FormItem>
        </Col>
        <Col md={6} xs={24}>
          <FormItem
            {...rules}
            label={labels && "Name of the institution"}
            name="institute"
          >
            {universities ? (
              <Select
              showSearch
              optionFilterProp="children" 
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              size="large" 
              placeholder="Institute">
                {universities &&
                  universities.map((uni: any) => {
                    return (
                      <Select.Option key={uni.id} value={uni.name}>
                        {uni.name}
                      </Select.Option>
                    );
                  })}
              </Select>
            ) : (
              <Input size="large" placeholder="Institute" />
            )}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};
