import {
  Button,
  Divider,
  Drawer,
  Pagination,
  Skeleton,
  Space,
  Table,
  notification,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CentreForm from "../../../components/FormGenerator/CentreForm";
import { apiList } from "../../../services/api-list";
import { getData, REACT_APP_STORAGE_URL } from "../../../services/api-service";

const ExamCentres = () => {
  const router = useHistory();
  const [tabLoading, setTabLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [list, setList] = useState<any>();
  const [drawer, setDrawer] = useState<any>();
  const [admissions, setAdmissions] = useState<any>();
  const [addRules, setAddRules] = useState<any>();

  const getAdmissions = async () => {
    const res = await getData({
      query: apiList?.admissionList,
    });
    if (res?.data?.code === 200) {
      setAdmissions(res?.data?.data?.admissions?.data);
    }
  };

  const getList = async () => {
    setTabLoading(true);
    let data = {
      sorting_type: "desc",
      pagination: 10,
      page: page,
      sorting: "id",
    };
    const res = await getData({
      query: apiList?.centreList,
      data: data,
    });
    if (res?.data?.code === 200) {
      setAddRules(res.data?.data?.center_add_rules);
      setList(res?.data?.data?.exam_centers);
      setTotal(res?.data?.data?.exam_centers?.total);
    }
    setTabLoading(false);
  };

  const downloadingAttendencePDF = async (id: number, type: string) => {
    const res = await getData({
      query: apiList?.attendancePDF,
      data: {
        admission_id: id,
        module: type,
      },
    });
    if (res?.data)
      notification.info({
        message: res?.data?.message,
      });
  };

  useEffect(() => {
    getAdmissions();
    getList();
  }, [page]);

  const columns = [
    // { title: "Title", dataIndex: "center", key: "center" },
    {
      title: "Admission",
      key: "admission_id",
      render: (text: string, record: any) => (
        <Space size="middle">
          {
            admissions.filter((item: any) => item.id == record?.admission_id)[0]
              ?.title
          }
        </Space>
      ),
    },
    // { title: "From Roll", dataIndex: "from_roll_no", key: "from_roll_no" },
    // { title: "To Roll", dataIndex: "to_roll_no", key: "to_roll_no" },
    {
      title: "From Roll",
      dataIndex: "from_roll_no",
      key: "from_roll_no",
    },
    {
      title: "To Roll",
      dataIndex: "to_roll_no",
      key: "to_roll_no",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Button type="primary" href={REACT_APP_STORAGE_URL+record.file}>
            Download
          </Button>
          <Button type="primary" onClick={() => downloadingAttendencePDF(record.admission_id, "attendance_pdf_center_wise")}>
            Attendance Sheet Download
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="dashboard-child">
      <div className="db-child-header">
        <h2>Exam centre list</h2>
        <Button
          disabled={!list || list.data.length >= 1}
          onClick={() => router?.push(`${router?.location?.pathname}/create`)}
          type="primary"
        >
          + Create Centre
        </Button>
      </div>
      <Divider />
      {admissions && (
        <Table
          loading={tabLoading}
          pagination={false}
          columns={columns}
          dataSource={list?.data}
        />
      )}
      {admissions && (
        <div className="space" style={{ float: "right" }}>
          <Pagination
            showSizeChanger={false}
            onChange={(value) => setPage(value)}
            total={total}
          />
        </div>
      )}
      {!admissions && <Skeleton className="space" active />}
      <Drawer
        title="Edit Centre Details"
        width={700}
        placement="right"
        closable={true}
        onClose={() => setDrawer(null)}
        visible={drawer}
        key="right"
      >
        {/* <AdmissionForm
          close={() => {
            getList();
            setDrawer(null);
          }}
          edit={drawer}
        /> */}
        <CentreForm
          close={() => {
            getList();
            setDrawer(false);
          }}
          edit={drawer}
        />
      </Drawer>
    </div>
  );
};

export default ExamCentres;
