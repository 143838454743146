import { Button, Card, Input, Form } from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../services/auth";
import { Header } from "../Header";
import "./login.scss";

export const Login = () => {
  const router = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const rules = { rules: [{ required: true, message: "Required" }] };

  const submit = async (values: any) => {
    setLoading(true);
    const res = await auth?.login(values);
    if (auth?.isLoggedIn() && res) window.location.href = "/dashboard";
    setLoading(false);
  };

  return (
    <div>
      <Header />
      <div className="login-wrapper">
        <Card>
          <h1>Login</h1>
          <Form layout="vertical" form={form} onFinish={submit}>
            <FormItem {...rules} name="mobile_no" label="Mobile No.">
              <Input size="large" placeholder="01X XXXX XXXX" />
            </FormItem>
            <FormItem {...rules} name="password" label="Password">
              <Input size="large" type="password" placeholder="Password" />
            </FormItem>
            <FormItem>
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                Login
              </Button>
            </FormItem>
          </Form>
        </Card>
      </div>
    </div>
  );
};
