import React, { useEffect, useState } from "react";
import { CheckCircleTwoTone } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

export const ThankYouComponent = () => {
  const router = useHistory();

  return (
    <div style={{display: "flex", flexDirection:"column", justifyContent: "center", alignItems:"center", marginTop: "5em"}}>
      <CheckCircleTwoTone twoToneColor="#52c41a" style={{fontSize: "15em", paddingBottom: 30}}/>
      <h1 style={{fontWeight:700}}>Thank you!</h1>
      <h1 style={{fontWeight:400}}>Your submission has been sent</h1>
    </div>
  );
};
