import React, { useState } from "react";
import "./search-filter.scss";
import {
  Input,
  Button,
  Select,
  Empty,
  Form,
  Modal,
  Row,
  Col,
  DatePicker,
} from "antd";
import moment from "moment";

const SearchFilter = ({
  filters,
  applyFilter,
}: {
  filters?: any;
  applyFilter?: any;
}) => {
  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [values, setValues] = useState();

  const activateFilter = async () => {
    applyFilter(values);
    setModal(false);
  };

  return (
    <div className="search-filter-wrapper">
      <div className="filter">
        <Button onClick={() => setModal(true)} type="primary">
          Filter
        </Button>
      </div>
      <Modal
        title="Filter"
        centered
        visible={modal}
        footer={false}
        onCancel={() => setModal(false)}
      >
        {filters && (
          <Form form={form} layout="vertical" onFinish={activateFilter}>
            <Row gutter={22}>
              {filters.map((filter: any) => {
                switch (filter?.type) {
                  case "drop-down":
                    return (
                      <Col key={filter.id} span={12}>
                        <Form.Item label={filter.name} name={filter.name}>
                          <Select
                            allowClear
                            onChange={(v) =>
                              setValues((values: any) => ({
                                ...values,
                                [filter.return]: v,
                              }))
                            }
                            placeholder={filter.name}
                          >
                            {filter.options.map((opt: any) => (
                              <Select.Option key={opt.name} value={opt.value}>
                                {opt.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    );
                  case "input":
                    return (
                      <Col span={12} key={filter.id}>
                        <Form.Item label={filter.name} name={filter.id}>
                          <Input
                            placeholder={filter.name}
                            onChange={(e) => {
                              setValues((values: any) => ({
                                ...values,
                                [filter.return]: e?.target?.value,
                              }));
                            }}
                          />
                        </Form.Item>
                      </Col>
                    );
                  case "date-from-to":
                    return (
                      <>
                        <Col span={12}>
                          <Form.Item name="date_from" label="Date from">
                            <DatePicker
                              onChange={(date) =>
                                setValues((values: any) => ({
                                  ...values,
                                  date_from: moment(date).format("YYYY-MM-DD"),
                                }))
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name="date_to" label="Date to">
                            <DatePicker
                              onChange={(date) =>
                                setValues((values: any) => ({
                                  ...values,
                                  date_to: moment(date).format("YYYY-MM-DD"),
                                }))
                              }
                            />
                          </Form.Item>
                        </Col>
                      </>
                    );
                  default:
                    return <Empty key={Math.random()} description="Empty" />;
                }
              })}
            </Row>
            <Form.Item>
              <Button size="large" block htmlType="submit" type="primary">
                Filter
              </Button>
            </Form.Item>
            {values && (
              <Form.Item>
                <Button
                  onClick={() => {
                    applyFilter();
                    form.resetFields();
                    setModal(false);
                  }}
                  size="large"
                  block
                  type="primary"
                  ghost
                >
                  Clear filters
                </Button>
              </Form.Item>
            )}
          </Form>
        )}
        {!filters && <Empty description="No filters found" />}
      </Modal>
    </div>
  );
};

export default SearchFilter;
