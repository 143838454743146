import {
  Button,
  Col,
  Descriptions,
  Divider,
  Drawer,
  notification,
  Row,
  Skeleton,
  Table,
} from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { calculateTimeDuration } from "../../../services/helper";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiList } from "../../../services/api-list";
import { base_url, getData, REACT_APP_STORAGE_URL } from "../../../services/api-service";
import RegistrationForm from "../../Registration";

const ApplicantDetails = ({ match }: any) => {
  const router = useHistory();
  const { Item } = Descriptions;
  const [details, setDetails] = useState<any>();
  const [drawer, setDrawer] = useState<any>();
  const getDetails = async () => {
    const res = await getData({
      query: `${apiList?.applicantDetails}/${match?.params?.details}`,
    });
    if (res?.data?.code == 200) setDetails(res?.data?.data);
    else notification?.error({ message: "Error" });
  };

  const eduColumns = [
    {
      title: "Name of exam",
      key: "name",
      render: (text: string, record: any) => record.degree.name,
    },
    { title: "Year of passing", key: "2", dataIndex: "passing_year" },
    { title: "System", dataIndex: "result_type", key: "result_type" },
    { title: "Result", dataIndex: "result", key: "result" },
    { title: "Area/Subject", dataIndex: "subject", key: "subject" },
    { title: "Name of the institution", key: "9", dataIndex: "institute" },
  ];

  const jobColumns = [
    { title: "Organization", dataIndex: "organization", key: "1" },
    { title: "Position", key: "2", dataIndex: "designation" },
    { title: "Responsibility", dataIndex: "responsibility", key: "3" },
    { title: "Start Date", dataIndex: "date_form", key: "4" },
    {
      title: "End Date",
      key: "end_date",
      render: (text: string, record: any) =>
        record.currently_working === 1 ? "Currently Working" : record.date_to,
    },
    {
      title: "Duration",
      key: "end_date",
      render: (row: any) =>calculateTimeDuration(row.date_to, row.date_form),
    }
  ];

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    drawer == false && getDetails();
  }, [drawer]);

  return (
    <div className="dashboard-child">
      <div className="db-child-header">
        <h2>Personal Information</h2>
        <Row gutter={12}>
          {details?.is_shortlisted == 0 ? <Col>
            <Button onClick={() => setDrawer(details)} type="primary">
              Edit
            </Button>
          </Col>: null}
          <Col>
            <Button
              onClick={() => router?.push("/dashboard/applicants")}
              type="primary"
            >
              Go back
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      {details && (
        <div className="space details-wrapper">
          <div className="details-personal-info">
            <div>
              <Avatar
                shape="square"
                size={200}
                src={details?.photo && REACT_APP_STORAGE_URL + details?.photo}
              />
            </div>
            <div className="space" />
            <div style={{ width: "100%" }}>
              <Descriptions column={1} size="small" bordered>
                <Item label="Name">{details?.name}</Item>
                <Item label="Father's name">{details?.father_name}</Item>
                <Item label="Mother's name">{details?.mother_name}</Item>
                <Item label="Mobile no.">{details?.mobile_no}</Item>
                <Item label="Email">{details?.email}</Item>
                <Item label="Roll no.">{details?.application?.roll_no}</Item>
                <Item label="Date of birth">{details?.date_of_birth}</Item>
                <Item label="Program type">
                  {details?.application?.program_type}
                </Item>
                <Item label="Country">{details?.country?.name}</Item>
                <Item label="Gender">{details?.gender}</Item>
                <Item label="National ID Number">{details?.nid}</Item>
                {/* <Item label="Student type">{details?.type}</Item> */}
              </Descriptions>
            </div>
          </div>
          <div className="space" />
          <Divider />
          <div className="half-space" />
          <h2>Educational qualifications</h2>
          <Divider />
          <Table
            pagination={false}
            columns={eduColumns}
            dataSource={details?.education}
          />
          <div className="space" />
          <Divider />
          <div className="half-space" />
          <h2>Work experiences</h2>
          <Divider />
          <Table
            pagination={false}
            columns={jobColumns}
            dataSource={details?.jobs}
          />
          <div className="space" />
          <Divider />
          <div className="half-space" />
          <h2>Other qualifications</h2>
          <p>{details?.qualifications}</p>
          <Divider />
          <div className="space" />
          <Avatar
            size={128}
            shape="square"
            src={REACT_APP_STORAGE_URL + details?.signature}
          />
          <div className="half-space" />
          <Divider />
          <h6>Signature</h6>
        </div>
      )}
      {!details && <Skeleton active className="space" />}
      <Drawer
        title="Edit info"
        placement="right"
        onClose={() => setDrawer(false)}
        visible={drawer}
        width="90%"
      >
        <RegistrationForm close={() => setDrawer(false)} edit={details} />
      </Drawer>
    </div>
  );
};

export default ApplicantDetails;
