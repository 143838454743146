import React from "react";
import { ThankYouComponent } from "../../components/ThankYouComponent";
import { Header } from "../../components/Header";

const ThankYou = () => {
  return (
    <div className="container">
      <Header />
      <ThankYouComponent />
    </div>
  );
};

export default ThankYou;
