import {
  Row,
  Form,
  Col,
  Input,
  DatePicker,
  Select,
  message,
  Radio,
  Alert,
} from "antd";
// import FormItem from "antd/lib/form/Form.Item";
import React, { useEffect, useState } from "react";
import { FormGenProps } from "../../Models";
import moment from "moment";

const genderOptions = [
  { id: 1, value: "Male", name: "Male" },
  { id: 2, value: "Female", name: "Female" },
  { id: 3, value: "Other", name: "Other" },
];
const programOptions = [
  { id: 1, name: "Full-Time", value: "Full-Time" },
  { id: 2, name: "Part-Time", value: "Part-Time" },
];
const studentOptions = [
  // { id: 1, name: "Foreign", value: 1 },
  { id: 2, name: "Local", value: 0 },
  {
    id: 1,
    name:
      "Foreign (If you have a foreign bachelor degree you need to contact IBA for equivalence)",
    value: 1,
  },
];

export const PersonalInfoForm = ({
  isSubmitting,
  fields,
  countries,
  setPer,
  edit,
  fileList,
  adType,
  setPtype,
}: FormGenProps) => {
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any>();
  const [stdType, setStdType] = useState<any>();
  const rules = { rules: [{ required: true, message: "Required" }] };

  useEffect(() => {
    if (isSubmitting) {
      fileList(files);
      setPer(form);
    }
  }, [isSubmitting]);

  useEffect(() => {
    setPer(form);
    edit &&
      form.setFieldsValue({
        ...edit,
        program_type: edit?.application?.program_type,
        date_of_birth: moment(edit?.date_of_birth),
        pic: null,
        sig: null,
      });
  }, []);

  return (
    <Form
      scrollToFirstError={true}
      onValuesChange={() => fileList(files)}
      layout="vertical"
      form={form}
      initialValues={{
        country_id: 20,
      }}
    >
      <Row gutter={24}>
        <Col md={12} xs={24}>
          <Form.Item {...rules} name="name" label="Name">
            <Input size="large" placeholder="Name" />
          </Form.Item>
          <Form.Item {...rules} name="father_name" label="Father's name">
            <Input size="large" placeholder="Father's name" />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Required" },
              {
                pattern: new RegExp(/^01[13-9]\d{8}$/),
                message: "Incomplete or Invalid format!",
              },
            ]}
            name="mobile_no"
            label="Mobile no."
          >
            <Input
              prefix="+88"
              type="number"
              size="large"
              placeholder="Mobile no."
            />
          </Form.Item>
          <Form.Item {...rules} name="gender" label="Gender">
            <Radio.Group size="large">
              {genderOptions?.map((opt: any) => (
                <Radio key={opt.id} value={opt.value}>
                  {opt?.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item {...rules} name="program_type" label="Program Type">
            <Radio.Group
              onChange={(e: any) => setPtype(e.target.value)}
              size="large"
            >
              {adType && adType === "Full-Time" ? (
                <Radio value="Full-Time">Full Time</Radio>
              ) : adType === "Part-Time" ? (
                <Radio value="Part-Time">Part Time</Radio>
              ) : (
                programOptions?.map((opt: any) => (
                  <Radio key={opt.id} value={opt.value}>
                    {opt?.name}
                  </Radio>
                ))
              )}
            </Radio.Group>
          </Form.Item>
          <Form.Item
            rules={edit ? [] : [...rules.rules]}
            name="pic"
            label="Photo (500kb max)"
          >
            <Input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={(e: any) => {
                const file = e.target.files[0];
                if (file.size > 500000) {
                  message.error("File size should be less than 500KB");
                  form.setFieldsValue({ photo: null });
                } else if (!file.type.includes("image")) {
                  message.error("File should be an image");
                  form.setFieldsValue({ photo: null });
                } else {
                  const reader = new FileReader();
                  reader.onload = () =>
                    // form.setFieldsValue({
                    //   photo: reader?.result,
                    // });
                    setFiles((files: any) => ({
                      ...files,
                      photo: reader.result,
                    }));
                  reader.readAsDataURL(file);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            rules={edit ? [] : [...rules.rules]}
            name="sig"
            label="Signature (500kb max)"
          >
            <Input
              type="file"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={(e: any) => {
                const file = e.target.files[0];
                if (file.size > 500000) {
                  message.error("File size should be less than 500KB");
                  form.setFieldsValue({ signature: null });
                } else if (!file.type.includes("image")) {
                  message.error("File should be an image");
                  form.setFieldsValue({ signature: null });
                } else {
                  const reader = new FileReader();
                  reader.onload = () =>
                    // form.setFieldsValue({
                    //   signature: reader?.result,
                    // });
                    setFiles((files: any) => ({
                      ...files,
                      signature: reader.result,
                    }));
                  reader.readAsDataURL(file);
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col md={12} xs={24}>
          <Form.Item {...rules} name="mother_name" label="Mother's name">
            <Input size="large" placeholder="Mother's name" />
          </Form.Item>
          <Form.Item {...rules} name="date_of_birth" label="Date of birth">
            <DatePicker
              disabledDate={(current: any) =>
                current && current > moment().endOf("day")
              }
              size="large"
            />
          </Form.Item>
          <Form.Item
            rules={[
              { required: true, message: "Required" },
              {
                pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                message: "Incomplete or Invalid format",
              },
            ]}
            name="email"
            label="Email"
          >
            <Input size="large" type="email" placeholder="Email" />
          </Form.Item>
          <Form.Item {...rules} name="country_id" label="Nationality">
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              size="large"
              placeholder="Nationility"
            >
              {countries &&
                countries.map((country: any) => {
                  return (
                    <Select.Option key={country.id} value={country.id}>
                      {country.name}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="nid" label="National ID Number">
            <Input type="number" size="large" placeholder="National ID Number" />
          </Form.Item>
          {/* {stdType === 1 && (
            <>
              <Alert
                type="warning"
                message="If you have a foreign bachelor degree you need to contact IBA
              for equivalent"
              />
              <div className="half-space" />
            </>
          )} */}
          <Form.Item {...rules} name="is_foreign" label="Bachelor Degree Type">
            <Radio.Group
              onChange={(val) => setStdType(val.target.value)}
              size="large"
            >
              {studentOptions?.map((opt: any) => (
                <Radio key={opt.id} value={opt.value}>
                  {opt?.name}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          {/* <Form.Item
            rules={edit ? [] : [...rules.rules]}
            name="sig"
            label="Signature (500kb max)"
          >
            <Input
              type="file"
              onChange={(e: any) => {
                const file = e.target.files[0];
                if (file.size > 500000) {
                  message.error("File size should be less than 500KB");
                  form.setFieldsValue({ signature: null });
                } else {
                  const reader = new FileReader();
                  reader.onload = () =>
                    // form.setFieldsValue({
                    //   signature: reader?.result,
                    // });
                    setFiles((files: any) => ({
                      ...files,
                      signature: reader.result,
                    }));
                  reader.readAsDataURL(file);
                }
              }}
            />
          </Form.Item> */}
        </Col>
      </Row>
    </Form>
  );
};
