import { Button, Divider, Drawer, Pagination, Skeleton, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiList } from "../../../services/api-list";
import { getData } from "../../../services/api-service";
import Create from "./Create";

const EditCircular = () => {
  const router = useHistory();
  const [tabLoading, setTabLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [list, setList] = useState<any>();
  const [edit, setEdit] = useState<any>();

  const getList = async () => {
    setTabLoading(true);
    let data = {
      sorting_type: "desc",
      pagination: 10,
      page: page,
      sorting: "id",
    };
    const res = await getData({
      query: apiList?.pageList,
      data: data,
    });
    if (res?.data?.code === 200) {
      setList(res?.data?.data?.pages);
      setTotal(res?.data?.data?.pages?.total);
    }
    setTabLoading(false);
  };

  useEffect(() => {
    getList();
  }, [page]);

  const columns: any = [
    { title: "Title", dataIndex: "title", key: "t" },
    { title: "Created at", dataIndex: "created_at", key: "ca" },
    { title: "Updated at", dataIndex: "updated_at", key: "ua" },
    {
      title: "Action",
      key: "a",
      render: (r: any) => (
        <Button onClick={() => setEdit(r)} type="primary">
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className="dashboard-child">
      <div className="db-child-header">
        <h2>Pages</h2>
        {/* <Button
          onClick={() => router?.push(`edit-circular/create`)}
          type="primary"
        >
          + Add Page
        </Button> */}
      </div>
      <Divider />
      {list && (
        <Table
          loading={tabLoading}
          pagination={false}
          columns={columns}
          dataSource={list?.data}
        />
      )}
      {list && (
        <div className="space" style={{ float: "right" }}>
          <Pagination
            showSizeChanger={false}
            onChange={(value) => setPage(value)}
            total={total}
          />
        </div>
      )}
      {!list && <Skeleton className="space" active />}
      {edit && (
        <Drawer
          title="Edit Page"
          width={700}
          placement="right"
          closable={true}
          onClose={() => setEdit(false)}
          visible={true}
          key="right"
        >
          <Create refresh={getList} setEdit={setEdit} edit={edit} />
        </Drawer>
      )}
    </div>
  );
};

export default EditCircular;
