import {
  Button,
  Checkbox,
  Col,
  Divider,
  Input,
  notification,
  Pagination,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiList } from "../../../services/api-list";
import { getData, postData } from "../../../services/api-service";
import {
  EyeOutlined,
  CheckOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import SearchFilter from "../../../components/SearchFilter";
import { filters } from "./filters";
import Modal from "antd/lib/modal/Modal";
import Form from "antd/lib/form/Form";
import FormItem from "antd/lib/form/FormItem";

const Applicants = () => {
  const router = useHistory();
  const [tabLoading, setTabLoading] = useState(false);
  const [slLoading, setSlLoading] = useState(false);
  const [dsLoading, setDsLoading] = useState(false);
  const [csvLoading, setCSVLoading] = useState(false); /// TODO button loader
  const [daLoading, setDaLoading] = useState(false);
  const [shortModal, setShortModal] = useState(false);
  const [total, setTotal] = useState<number>();
  const [page, setPage] = useState(1);
  const [list, setList] = useState<any>();
  const [verLoading, setVerLoading] = useState(false);
  const [admissionId, setAdmissionId] = useState<number>();
  const [shortlistTemp, setShortlistTemp] = useState<string[]>([]);
  const [filter, setFilter] = useState({});
  const [defaultCurrent, setDefaultCurrent] = useState<any>(1);

  // console.log("Reza", filter);

  const getApplicants = async (que: string, data: any) => {
    setTabLoading(true);
    const res = await getData({
      query: que,
      data: data,
    });
    if (res?.data?.code === 200) {
      console.log('data', res?.data);
      setAdmissionId(res?.data?.data?.admission_id);
      setList(res?.data?.data?.users?.data);
      setTotal(res?.data?.data?.users?.total);
    } else notification.error({ message: res?.data?.message });
    setTabLoading(false);
  };

  const applyFilter = (filters: any) => {
    setDefaultCurrent(1);
    setFilter(filters);
    getApplicants(apiList?.applicantList, {
      ...filters,
      pagination: 10,
      page: 1,
    });
  };

  const verifyStd = async (id: any) => {
    setVerLoading(id);
    const res = await postData({
      query: apiList.verifyForeign + id,
    });

    if (res?.data?.code == 200) {
      notification.success({
        message: "Applicant verified!",
      });
      getApplicants(apiList?.applicantList, {
        ...filters,
        pagination: 10,
        page: page,
      });
    } else
      notification.error({
        message: "Verification failed!",
      });
    setVerLoading(false);
  };

  const shortList = async (id: any) => {
    if (shortlistTemp?.length < 1 && !id)
      notification.error({
        message: "Select applicant to shortlist!",
      });
    else {
      setSlLoading(true);
      const res = await postData({
        query: apiList?.shortlistApplicant,
        data: {
          id: id ? `[${id}]` : `[${shortlistTemp}]`,
        },
      });
      if (res?.data?.code == 200) {
        notification.success({
          message: "Applicant(s) shortlisted!",
        });
        getApplicants(apiList?.applicantList, {
          ...filters,
          pagination: 10,
          page: page,
        });
        setShortModal(false);
      } else
        notification.error({
          message: "Shortlisting failed!",
        });
      setSlLoading(false);
    }
  };

  const downloading = async (type: string) => {
    if (type == "shortlisted_student_pdf") setDsLoading(true);
    else setDaLoading(true);
    const res = await getData({
      query: apiList?.bulkDownload,
      data: {
        admission_id: admissionId,
        module: type,
        ...filter
      },
    });
    if (res?.data)
      notification.info({
        message: res?.data?.message,
      });
    setDaLoading(false);
    setDsLoading(false);
  };

  const downloadingCSV = async (type: string) => {
    // setCSVLoading(true); /// TODO button loader
    const res = await getData({
      query: apiList?.shortlistedStudentCSV,
      data: {
        admission_id: admissionId,
        module: type,
        ...filter
      },
    });
    if (res?.data)
      notification.info({
        message: res?.data?.message,
      });
    // setCSVLoading(false); /// TODO button loader
  };

  useEffect(() => {
    getApplicants(apiList?.applicantList, {
      ...filter,
      pagination: 10,
      page: page,
    });

  }, [page]);

  const rowSelection = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setShortlistTemp(selectedRows?.map((sr: any) => `${sr.id}`));
    },
  };

  const columns = [
    // { title: "Sl No.", dataIndex: "sl", key: "sl" , 
    //  render : (text: string,record : any,index : any) => {
    //   return 10*(page-1)+(index+1);
    //  }
    // },
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Father Name", key: "father_name", dataIndex: "father_name" },
    { title: "Mobile No.", dataIndex: "mobile_no", key: "mobile_no" },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Date Of Birth",
      key: "date_of_birth",
      dataIndex: "date_of_birth",
    },
    { title: "Roll No.", dataIndex: "roll_no", key: "roll_no" },
    {
      title: "Shortlist",
      key: "is_shortlisted",
      dataIndex: "is_shortlisted",
      render: (text: string, record: any) => {
        if (record.is_shortlisted) {
          return (
            <Tag
              key={record.id}
              color={record.is_shortlisted === 1 ? "#74a758" : "#be4652"}
            >
              {record.is_shortlisted === 1 ? "Shortlisted" : "Unverified"}
            </Tag>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Type",
      key: "is_foreign",
      dataIndex: "is_foreign",
      render: (text: string, record: any) => {
        return (
          <Tag
            key={record.id}
            color={record.is_foreign === 0 ? "green" : "red"}
          >
            {record.is_foreign === 0 ? "Local" : "Foreign"}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, record: any) => (
        <Space size="middle">
          <Tooltip title="View details">
            <Button
              type="primary"
              icon={<EyeOutlined />}
              size="large"
              onClick={() => router.push(`/dashboard/applicants/${record.id}`)}
            />
          </Tooltip>
          <Tooltip title="Download">
            <Button
              type="primary"
              icon={<VerticalAlignBottomOutlined />}
              size="large"
              onClick={async () => {
                const res = await getData({
                  query: `${apiList?.applicantDownload}/${record.id}`,
                });
                if (res?.data?.data?.url)
                  window.open(res?.data?.data?.url, "_blank");
                else notification.error({ message: "Something went wrong!" });
              }}
            />
          </Tooltip>
          {record?.is_foreign === 1 && record?.verify_foreign === 0 && (
            <Popconfirm
              placement="topLeft"
              title="Verify this student?"
              onConfirm={() => verifyStd(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Verify">
                <Button
                  loading={verLoading === record.id}
                  size="large"
                  icon={<CheckOutlined />}
                  type="primary"
                />
              </Tooltip>
            </Popconfirm>
          )}
          {record?.is_shortlisted === 0 ? (
            <Popconfirm
              placement="topLeft"
              title="Shortlist this student?"
              onConfirm={() => shortList(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Shortlist">
                <Button
                  size="large"
                  icon={<CheckOutlined />}
                  type="primary"
                  ghost
                />
              </Tooltip>
            </Popconfirm>
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  return (
    <div style={{ overflowX: "hidden" }} className="dashboard-child">
      <div className="db-child-header">
        <h2>Applicants list</h2>
        <Row gutter={12}>
          <Col>
            <Button
              loading={daLoading}
              onClick={() => downloading("all_student_pdf")}
              type="primary"
            >
              Download All
            </Button>
          </Col>
          <Col>
            <Button
              loading={dsLoading}
              onClick={() => downloading("shortlisted_student_pdf")}
              type="primary"
            >
              Download Shortlisted (pdf)
            </Button>
          </Col>
          <Col>
            <Button
              loading={csvLoading}
              onClick={() => downloadingCSV("shortlisted_student_csv")}
              type="primary"
            >
              Download Shortlisted (csv)
            </Button>
          </Col>
          <Col>
            <SearchFilter filters={filters} applyFilter={applyFilter} />
          </Col>
          <Col>
            <Button onClick={() => setShortModal(true)} type="primary">
              Shortlist by ID
            </Button>
          </Col>
          <Col>
            <Button
              loading={slLoading}
              onClick={() => shortList(null)}
              type="primary"
            >
              Shortlist
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      <Table
        style={{ overflow: "auto" }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        loading={tabLoading}
        pagination={false}
        columns={columns}
        rowKey={"id"}
        dataSource={list?.map((item: any, key: number) => ({
          key: key,
          ...item,
        }))}
      />
      <div className="space" style={{ float: "right" }}>
        {total && (
          <Pagination
            current={defaultCurrent}
            showSizeChanger={false}
            onChange={(value) => {
              setPage(value);
              setDefaultCurrent(value);
            }}
            total={total}
          />
        )}
      </div>
      <Modal
        title="Shortlist candidates by ID"
        centered
        visible={shortModal}
        onCancel={() => setShortModal(false)}
        footer={false}
      >
        <FormItem label="Enter IDs to be shortlisted (Comma separated)">
          <Input
            placeholder="i.e. 2,3,5,7,9"
            size="large"
            onChange={(e) =>
              setShortlistTemp(e?.target?.value?.split(" ").join("").split(","))
            }
          />
        </FormItem>
        <FormItem>
          <Button
            loading={slLoading}
            onClick={() => shortList(null)}
            type="primary"
            size="large"
          >
            Confirm
          </Button>
        </FormItem>
      </Modal>
    </div>
  );
};

export default Applicants;
