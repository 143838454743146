import React from "react";
import { Route, Switch } from "react-router-dom";
import { authRoutes } from "../../routes/routes";

const Auth = () => {
  return (
    <Switch>
      {authRoutes?.map((item) => (
        <Route key={item?.id} path={item?.path} component={item?.component} exact/>
      ))}
    </Switch>
  );
};

export default Auth;
