import moment from "moment";
export const calculateTimeDuration = (date1: any, date2: any) => {
    if (date1 && date2) {
        let durationString = '';
        // start time and end time
        let startTime = moment(date1, 'YYYY/MM/DD HH:mm');
        let endTime = moment(date2, 'YYYY/MM/DD HH:mm');

        let years = startTime.diff(endTime, 'year');
        endTime.add(years, 'years');

        let months = startTime.diff(endTime, 'months');
        endTime.add(months, 'months');

        let days = startTime.diff(endTime, 'days');

        if (years) {
            durationString = `${years}years`;
        }

        if (months) {
            durationString = `${durationString} ${months}months`;
        }

        if (days) {
            durationString = `${durationString} ${days}days`;
        }

        if (durationString == "") {
            durationString = '1day'
        }

        return durationString;
    }else{
        return `0days`;
    }
};

export const checkTimeDuration = (date1: any, date2: any) => {
    if (date1 && date2) {
        let duration = 0;
        // start time and end time
        let startTime = moment(date1, 'YYYY/MM/DD HH:mm');
        let endTime = moment(date2, 'YYYY/MM/DD HH:mm');
        duration = moment(startTime).diff(moment(endTime));
        return duration;
    }else{
        return 0;
    }
};
  