import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Skeleton,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import React, { useEffect, useState } from "react";
import { getData, postData, REACT_APP_URL } from "../../services/api-service";
import { useHistory } from "react-router-dom";
import { apiList } from "../../services/api-list";

const CentreForm = ({ edit, close }: { edit?: any; close?: any }) => {
  const router = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [addRules, setAddRules] = useState<any>();
  const [seatPlan, setSeatPlan] = useState<any>();
  const rules = { rules: [{ required: true, message: "Required" }] };

  const getRules = async () => {
    let data = {
      sorting_type: "desc",
      pagination: 10,
      page: 1,
      sorting: "id",
    };
    const res = await getData({
      query: apiList?.centreList,
      data: data,
    });
    if (res?.data?.code === 200) {
      setAddRules(res?.data?.data?.center_add_rules);
    }
  };

  useEffect(() => {
    edit && form.setFieldsValue(edit);
  }, [edit]);

  useEffect(() => {
    addRules &&
      form.setFieldsValue({
        from_roll_no: addRules?.minimum_roll,
      });
  }, [addRules]);

  useEffect(() => {
    getRules();
  }, []);

  const submit = async (values: any) => {
    setLoading(true);
    let formData = new FormData();
    // formData.append("center", values.center);
    // formData.append("from_roll_no", values.from_roll_no);
    // formData.append("to_roll_no", values.to_roll_no);
    formData.append("file", seatPlan);
    formData.append("admission_id", addRules?.admission_id);

    const res = await postData({
      query: edit
        ? `${apiList?.updateCentre}/${edit?.id}`
        : apiList?.createCentre,
      data: formData,
    });
    if (res?.data?.code == 200) {
      notification.success({
        message: "Success",
        description: edit ? "Centre updated!" : "Centre created!",
      });
      if (edit) close();
      else router?.push("/dashboard/centres");
    } else {
      res?.data?.errors
        ? Object.values(res?.data?.errors).map((item: any) => {
            notification?.error({
              message: item[0],
            });
          })
        : notification?.error({
            message: res?.data?.message,
          });
    }
    setLoading(false);
  };

  return (
    <Form layout="vertical" form={form} onFinish={submit}>
      {addRules && (
        <div className="space">
          <Alert
            message="Admission"
            description={
              <p style={{ margin: 0 }}>
                Create centre for{" "}
                <span style={{ fontWeight: "bold" }}>
                  {addRules?.admission_title}
                </span>{" "}
                admission 
                {" "}<a href={`${REACT_APP_URL}demo_file_room_wise_seat_plan.xlsx`}>Sample Download</a>
              </p>
            }
            type="info"
            showIcon
          />
          <div className="space" />
          <Row gutter={12}>
            <Col md={12} xs={24}>
              {/* <FormItem {...rules} name="center" label="Centre Name">
                <Input size="large" placeholder="Centre name" />
              </FormItem>
              <FormItem {...rules} name="from_roll_no" label="Roll from">
                <Input
                  type="number"
                  disabled={true}
                  size="large"
                  placeholder="Roll from"
                />
              </FormItem>
              <FormItem {...rules} name="to_roll_no" label="Roll to">
                <Input size="large" placeholder="Roll to" />
              </FormItem> */}
              <FormItem label="Seat plan">
                <Input
                  type="file"
                  onChange={(e: any) => {
                    setSeatPlan(null);
                    const file = e.target.files[0];
                    console.log(file);
                    setSeatPlan(file);
                  }}
                />
              </FormItem>
              <FormItem>
                <Button
                  loading={loading}
                  block
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  Submit
                </Button>
              </FormItem>
            </Col>
            {/* <Col md={12} xs={24}></Col> */}
          </Row>
        </div>
      )}
      {!addRules && <Skeleton className="space" active />}
    </Form>
  );
};

export default CentreForm;
