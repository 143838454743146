import React from "react";
import {
  BrowserRouter,
  HashRouter,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { routes } from "../routes/routes";
import { auth } from "../services/auth";
import Welcome from "./Welcome";

const App = () => {
  // console.log = function () {}; // disable all console logs

  const getLink = () => {
    if (auth?.getUserRole() == "student")
      return <Redirect to="/dashboard/student" />;
    else if (auth?.getUserRole() == "system")
      return <Redirect to="/dashboard/admissions" />;
    else {
      auth?.logout();
      return "";
    }
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          {auth?.isLoggedIn() ? getLink() : <Welcome />}
        </Route>
        <Route path="/dashboard" exact>
          {auth?.isLoggedIn() ? getLink() : <Welcome />}
        </Route>
        {/* <Route path="/apply" exact>
          {auth?.isLoggedIn() ? getLink() : <Welcome />}
        </Route> */}
        {routes?.map(
          (item) =>
            item && (
              <Route
                key={item?.id}
                path={item?.path}
                component={item?.component}
                exact
              />
            )
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default App;
