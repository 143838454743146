import React from "react";
import { Circular } from "../../components/Circular";
import { Header } from "../../components/Header";

const Welcome = () => {
  return (
    <div className="container">
      <Header />
      <Circular />
    </div>
  );
};

export default Welcome;
