import { notification } from "antd";
import cookies from "js-cookie";
import { apiList } from "./api-list";
import { postData } from "./api-service";

interface loginData {
  mobile: number;
  password: string;
  type?: string;
}

const login = async (data: loginData) => {
  const res = await postData({
    query: apiList.login,
    data: data,
    noToken: true,
  });
  if (res && res?.data?.code === 200) {
    cookies.set("token", res?.data?.data?.user?.api_token);
    cookies.set("user", JSON.stringify(res?.data?.data?.user));
    return true;
  } else {
    notification?.error({
      message: res?.data?.message || "Something went wrong!",
    });
    return false;
  }
};

const logout = () => {
  cookies.remove("token");
  cookies.remove("user");
  window.location.href = "/";
};

const getToken = () => {
  return JSON.parse(cookies.get("token") || "");
};

const isLoggedIn = (): boolean => {
  if (cookies.get("token")) return true;
  else return false;
};

const getUserData = () => {
  if (cookies.get("user")) return JSON.parse(cookies.get("user") || "");
  else return false;
};

const getUserRole = () => {
  if (cookies.get("user")) {
    const user = JSON.parse(cookies.get("user") || "");
    return user?.type || false;
  } else return false;
};

//collection of methods for auth related services
export const auth = {
  login,
  logout,
  isLoggedIn,
  getUserData,
  getUserRole,
  getToken,
};
