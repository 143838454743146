import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { apiList } from "../../../services/api-list";
import { postData } from "../../../services/api-service";

const SendNotification = () => {
  const router = useHistory();
  const [form] = Form?.useForm();
  //   const [data, setData] = useState<any>();
  const [med, setMed] = useState<string>();
  const [loading, setLoading] = useState(false);
  const rules = { rules: [{ required: true, message: "Required" }] };

  const submit = async (values: any) => {
    setLoading(true);
    const res = await postData({
      query: apiList?.sendNotification,
      data: values,
    });
    if (res?.data?.code == 200) {
      notification?.success({ message: "Notification sent!" });
      form.resetFields();
    } else notification?.error({ message: res?.data?.message || "Error!" });
    setLoading(false);
  };

  //   const loadMore = async () => {};

  return (
    <div className="dashboard-child">
      <div className="db-child-header">
        <h2>Send notification to candidates</h2>
        {/* <Button type="primary">+ Create Centre</Button> */}
      </div>
      <Divider />
      <Form layout="vertical" className="space" form={form} onFinish={submit}>
        <Row>
          <Col span={12}>
            <Form.Item
              {...rules}
              name="notification_medium"
              label="Notification Medium"
            >
              <Select
                onChange={(value: string) => setMed(value)}
                size="large"
                placeholder="Select Medium"
              >
                <Select.Option value="sms">SMS</Select.Option>
                <Select.Option value="email">Email</Select.Option>
                <Select.Option value="both">Both</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item {...rules} name="student_type" label="Student type">
              <Select size="large" placeholder="Select type">
                <Select.Option value="local">Local</Select.Option>
                <Select.Option value="foreign">Foreign</Select.Option>
                <Select.Option value="all">All</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item {...rules} name="category" label="Category">
              <Select size="large" placeholder="Select category">
                <Select.Option value="shortlisted">Shortlisted</Select.Option>
                <Select.Option value="not_shortlisted">
                  Non-shortlisted
                </Select.Option>
                <Select.Option value="all">All</Select.Option>
              </Select>
            </Form.Item>
            {med != "sms" && (
              <Form.Item name="subject" label="Subject">
                <Input size="large" placeholder="Subject" />
              </Form.Item>
            )}
            <Form.Item name="body" label="Body">
              <Input.TextArea placeholder="Body" />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                loading={loading}
                type="primary"
                size="large"
                block
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SendNotification;
