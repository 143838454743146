import axios from "axios";
import { auth } from "./auth";
import cookies from "js-cookie";
import { notification } from "antd";
// live
// export const base_url = "http://iba-seip.registrationbd.com/iba-pgd-admission/public/api/v1/";
// export const REACT_APP_URL = "http://iba-seip.registrationbd.com/iba-pgd-admission/public/";
// export const REACT_APP_STORAGE_URL = "http://iba-seip.registrationbd.com/iba-pgd-admission/public/storage/";

// demo
export const base_url = "https://iba-pgd.publicdemo.xyz/iba-pgd-admission/public/api/v1/";
export const REACT_APP_STORAGE_URL = "https://iba-pgd.publicdemo.xyz/iba-pgd-admission/public/storage/";
export const REACT_APP_URL = "https://iba-pgd.publicdemo.xyz/iba-pgd-admission/public/";

interface payload {
  query: string;
  data?: any;
  noToken?: boolean;
}

const generateHeaders = () => ({
  Authorization: `Bearer ${cookies.get("token")}`,
});

export const postData = async ({ query, data, noToken }: payload) => {
  try {
    let res = await axios({
      method: "POST",
      url: base_url + query,
      headers: !noToken && generateHeaders(),
      data: data,
    });
    return res;
  } catch (error) {
    console.log("HTTP ERROR   >>>>>", error.response);
    if (error?.response?.status === 401) auth?.logout();
  }
};

export const getData = async ({ query, data, noToken }: payload) => {
  try {
    let res = await axios({
      method: "GET",
      url: base_url + query,
      headers: !noToken && generateHeaders(),
      params: data,
    });
    return res;
  } catch (error) {
    if (error?.response?.status === 401) auth?.logout();
    else console.log("HTTP ERROR   >>>>>", error.response);
  }
};
