export const filters = [
  {
    id: 1,
    type: "input",
    name: "Student name",
    return: "name",
  },
  {
    id: 2,
    type: "input",
    name: "Student phone",
    return: "mobile_no",
  },
  {
    id: 3,
    type: "input",
    name: "Student role",
    return: "roll_no",
  },
  {
    id: 4,
    type: "drop-down",
    name: "Student type",
    return: "is_foreign",
    options: [
      { value: 0, name: "Local" },
      { value: 1, name: "Foreign" },
    ],
  },
  {
    id: 5,
    type: "drop-down",
    name: "Sort By",
    return: "sorting",
    options: [
      { value: "id", name: "ID" },
      { value: "name", name: "Name" },
    ],
  },
  {
    id: 6,
    type: "drop-down",
    name: "Sorting type",
    return: "sorting_type",
    options: [
      { value: "asc", name: "Ascending" },
      { value: "desc", name: "Descending" },
    ],
  },
  { id: 7, type: "date-from-to" },
  {
    id: 8,
    type: "drop-down",
    name: "Program type",
    return: "program_type",
    options: [
      { value: "day", name: "Day" },
      { value: "evening", name: "Evening" },
    ],
  },
];
