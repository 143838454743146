import { FormField } from "../../Models";

export const primaryFields: FormField[] = [
  { id: 1, name: "name", label: "Name", type: "input" },
  { id: 2, name: "mother_name", label: "Mothers Name", type: "input" },
  { id: 3, name: "father_name", label: "Fathers Name", type: "input" },
  { id: 4, name: "date_of_birth", label: "Date of birth", type: "date" },
  { id: 5, name: "mobile_no", label: "Mobile no.", type: "number" },
  { id: 6, name: "email", label: "Email", type: "email" },
  {
    id: 7,
    name: "gender",
    label: "Gender",
    type: "drop-down",
    options: [
      { id: 1, value: "Male", name: "Male" },
      { id: 2, value: "Female", name: "Female" },
      { id: 3, value: "Other", name: "Other" },
    ],
  },
  {
    id: 8,
    name: "nationality",
    label: "Nationality",
    type: "countries",
  },
  {
    id: 9,
    name: "program_type",
    label: "Program type",
    type: "drop-down",
    options: [
      { id: 1, name: "Day", value: "Day" },
      { id: 2, name: "Evening", value: "Evening" },
    ],
  },
  {
    id: 10,
    name: "student_type",
    label: "Student type",
    type: "drop-down",
    options: [
      { id: 1, name: "Foreign", value: 1 },
      { id: 2, name: "Local", value: 0 },
    ],
  },
  { id: 11, name: "photo", label: "Photo (500kb max)", type: "file" },
  {
    id: 12,
    name: "signature",
    label: "Signature (500kb max)",
    type: "file",
  },
];
