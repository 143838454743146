import { Button, Col, Menu, Row } from "antd";
import React from "react";
import { Header } from "../../components/Header";
import {
  ArrowDownOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
  BellOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import "./dashboard.scss";
import {
  adminDashboardRoutes,
  studentDashboardRoutes,
} from "../../routes/routes";
import { Route, Switch, useHistory } from "react-router-dom";
import { auth } from "../../services/auth";

const Dashboard = () => {
  const router = useHistory();
  const adminMenu = [
    {
      id: "1",
      name: "Admission",
      path: "/dashboard/admissions",
      icon: <ContainerOutlined />,
    },
    {
      id: "2",
      name: "Applicants",
      path: "/dashboard/applicants",
      icon: <MailOutlined />,
    },
    {
      id: "3",
      name: "Exam centres",
      path: "/dashboard/centres",
      icon: <DesktopOutlined />,
    },
    {
      id: "5",
      name: "Notifications",
      path: "/dashboard/notifications",
      icon: <BellOutlined />,
    },
    {
      id: "4",
      name: "Downloads",
      path: "/dashboard/downloads",
      icon: <ArrowDownOutlined />,
    },
    {
      id: "6",
      name: "Pages",
      path: "/dashboard/edit-circular",
      icon: <SnippetsOutlined />,
    },
  ];

  const studentMenu = [
    {
      id: "1",
      name: "My Profile",
      path: "/dashboard/student",
      icon: <ContainerOutlined />,
    },
  ];

  const getMenu = () => {
    if (auth?.getUserRole()) {
      if (auth?.getUserRole() == "system") return adminMenu;
      else if (auth?.getUserRole() == "student") return studentMenu;
      else auth?.logout();
    }
  };

  const getRoutes = () => {
    if (auth?.getUserRole()) {
      if (auth?.getUserRole() == "system") return adminDashboardRoutes;
      else if (auth?.getUserRole() == "student") return studentDashboardRoutes;
      else auth?.logout();
    }
  };

  const getSelectedMenu = (): string[] => {
    const selected = adminMenu.filter((item: any) =>
      router?.location?.pathname?.includes(item?.path)
    );
    if (selected?.length < 1) return ["1"];
    else return [selected[0]?.id];
  };

  return (
    <>
      <div className="desktop container dashboard-wrapper">
        <Header />
        <Row>
          <Col span={4}>
            <Menu
              defaultSelectedKeys={getSelectedMenu()}
              theme="dark"
              className="menu"
              mode="vertical"
            >
              <div className="half-space" />
              {getMenu()?.map((item: any) => {
                return (
                  <Menu.Item
                    onClick={() => router?.push(item?.path)}
                    icon={item?.icon}
                    key={item?.id}
                  >
                    {item?.name}
                  </Menu.Item>
                );
              })}
            </Menu>
          </Col>
          <Col span={20}>
            <Switch>
              {getRoutes()?.map((item: any) => (
                <Route
                  key={item?.id}
                  path={item?.path}
                  component={item?.component}
                  exact
                />
              ))}
            </Switch>
          </Col>
        </Row>
      </div>
      <div className="mobile-alert">
        <h4>
          Please browse from a Desktop or enable desktop view on your phone
        </h4>
      </div>
    </>
  );
};

export default Dashboard;
