import { Button, Divider } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import AdmissionForm from "../../../components/FormGenerator/AdmissionForm";

const CreateAdmission = () => {
  const router = useHistory();
  const submit = () => {
    //   todo
  };
  return (
    <div className="dashboard-child">
      <div className="db-child-header">
        <h2>Create New Admission</h2>
        <Button
          onClick={() => router?.push("/dashboard/admissions")}
          type="primary"
        >
          Go back
        </Button>
      </div>
      <Divider />
      <AdmissionForm />
    </div>
  );
};

export default CreateAdmission;
