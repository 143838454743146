import React from "react";
import "./header.scss";
import logo from "../../assets/du-logo.png";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { auth } from "../../services/auth";

export const Header = () => {
  const router = useHistory();
  return (
    <div className="header-wrapper container">
      <div onClick={() => router.push("/")} className="logo-wrapper">
        <img src={logo} />
        <div>
          <h3 className="only-web">IBA</h3>
          <p>Institute of Business Administration</p>
          <p>University of Dhaka</p>
        </div>
      </div>
      <Button
        onClick={() =>
          auth?.isLoggedIn() ? auth?.logout() : router?.push("/auth/login")
        }
        type="primary"
      >
        {auth?.isLoggedIn() ? "Logout" : "Sign In"}
      </Button>
    </div>
  );
};
