import { useEffect, useState } from "react";
import { getData, postData } from "../../../services/api-service";
import { apiList } from "../../../services/api-list";
import CreatePage from "../../../components/CreatePage";
import { useHistory } from "react-router";
import {
  Button,
  Divider,
  Input,
  Row,
  Select,
  Col,
  Form,
  message,
  notification,
} from "antd";
import FormItem from "antd/lib/form/FormItem";

export default ({ edit, setEdit, refresh }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const router = useHistory();
  const rules = [{ required: true, message: "Required" }];

  const submit = async (values) => {
    setLoading(true);
    if (values && data?.contents && data?.content_right) {
      const res = await postData({
        query: edit ? `${apiList.editPage}${edit.id}` : apiList.createPage,
        data: { ...values, ...data },
      });
      if (res?.data?.code === 200) {
        refresh();
        notification.success({
          message: edit ? "Page updated" : "Page created",
        });
        if (edit) setEdit(false);
        else router?.push("/dashboard/edit-circular");
      } else {
        res?.data?.errors &&
          Object.values(res?.data?.errors).map((item) => {
            notification?.error({
              message: item[0],
            });
          });
      }
    } else {
      message.error("Please fill up all the fields");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue(edit);
    }
  }, []);

  return (
    <div className="dashboard-child">
      {!edit && (
        <>
          <div className="db-child-header">
            <h2>Create New Page</h2>
            <Button
              onClick={() => router?.push("/dashboard/edit-circular")}
              type="primary"
            >
              Go back
            </Button>
          </div>
          <Divider />
        </>
      )}
      <div style={{ padding: "1rem" }}>
        <Form form={form} onFinish={submit} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <FormItem rules={rules} name="title" label="Title">
                <Input size="large" placeholder="title" />
              </FormItem>
              <FormItem rules={rules} name="status" label="Status">
                <Select size="large" placeholder="Status">
                  <Select.Option value={0}>Inactive</Select.Option>
                  <Select.Option value={1}>Active</Select.Option>
                </Select>
              </FormItem>
            </Col>
            <Col span={12}>
              <FormItem rules={rules} name="slug" label="Page">
                <Select size="large" placeholder="Page type">
                  <Select.Option value="home-page">Home Page</Select.Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <CreatePage edit={edit} setData={setData} />
          <div className="space" />
          <Button loading={loading} block type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};
